import {
  useExperiments,
  useCurrencyFormatter as useInfraCurrencyFormatter,
  useTranslation,
  FlowAPI,
} from '@wix/yoshi-flow-editor'
import {GetFormattedMoney, getFormattedMoney, ExperimentNames} from '@wix/wix-events-commons-statics'

export const getWorkerCurrencyFormatter = (flowApi: FlowAPI) => {
  const {t} = flowApi.translations
  const enabled = flowApi.experiments.enabled(ExperimentNames.UseInfraCurrencyFormatter)

  return getCurrencyFormatter({formatCurrency: flowApi.formatCurrency, t, enabled})
}

const getCurrencyFormatter = ({enabled, t, formatCurrency}) => {
  if (enabled) {
    return {
      getFormattedMoney: (price: wix.events.Money) => {
        return formatCurrency({value: price.amount, currency: price.currency})
      },
      getTicketPriceText: (price: wix.events.Money) => {
        const amount = Number(price.amount)

        if (!amount) {
          return t('ticketPrice.free')
        }

        return formatCurrency({value: price.amount, currency: price.currency})
      },
    }
  } else {
    return {
      getTicketPriceText: (price: wix.events.Money) => {
        return Number(price.amount) ? getFormattedMoney(price) : t('ticketPrice.free')
      },
      getFormattedMoney,
    }
  }
}

export const useCurrencyFormatter = () => {
  const {experiments} = useExperiments()
  const formatCurrency = useInfraCurrencyFormatter()
  const {t} = useTranslation()
  const enabled = experiments.enabled(ExperimentNames.UseInfraCurrencyFormatter)

  return getCurrencyFormatter({enabled, t, formatCurrency})
}

export type GetTicketPriceText = (price: wix.events.Money) => string

export type PriceFormatters = {getTicketPriceText: GetTicketPriceText; getFormattedMoney: GetFormattedMoney}
